@font-face {
  font-family: 'robotobold';
  src: url('../assets/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'robotolight';
  src: url('../assets/fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: 'robotomedium';
  src: url('../assets/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'robotoregular';
  src: url('../assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'latobold';
  src: url('../assets/fonts/Lato-Bold.ttf');
}

@font-face {
  font-family: 'latoitalic';
  src: url('../assets/fonts/Lato-Italic.ttf');
}

@font-face {
  font-family: 'latomedium';
  src: url('../assets/fonts/Lato-Medium.ttf');
}

@font-face {
  font-family: 'latoregular';
  src: url('../assets/fonts/Lato-Regular.ttf');
}

@font-face {
  font-family: 'gothambook';
  src: url('../assets/fonts/GothamBook.ttf');
}

@font-face {
  font-family: 'gothammedium';
  src: url('../assets/fonts/GothamMedium.ttf');
}

@font-face {
  font-family: 'gotham_promedium';
  src: url('../assets/fonts/GothamPro-Medium.ttf');
}

@font-face {
  font-family: 'gotham_proregular';
  src: url('../assets/fonts/GothamPro.ttf');
}

@font-face {
  font-family: 'gothambold';
  src: url('../assets/fonts/GothamPro-Bold.ttf');
}
